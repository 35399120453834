import logo from './resources/eastonlogo.png';
import './App.css';
import Nav from "./components/navbar"
import Home from "./pages/home"
import FAQ from "./pages/faq"
import Privacy from "./pages/privacy"
import Contact from "./pages/contact"
import WIP from "./pages/wip"
import JForm from "./pages/jform"
import Jdocup from "./pages/jdocup"
import CreditApp from "./pages/creditApp"
import achForm from "./pages/achform"
import DemoSignin from "./pages/demoSignin"
import DealerDashboard from "./pages/dealerDashboard"
import CustomerDashboard from "./pages/customerDashboard"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Link, animateScroll as scroll } from "react-scroll";
import "focus-visible/dist/focus-visible"

function App() {
  return (
      <Router>
	<Nav />
      <Switch>
	  <Route path='/' exact component={Home} />
	  <Route path='/FAQ' component={FAQ} />
	  <Route path='/privacy' component={Privacy} />
	  <Route path='/contact' component={Contact} />
	  <Route path='/wip' component={WIP} />
	  <Route path='/creditApplication' component={CreditApp} />
	  <Route path='/application' component={JForm} />
	  <Route path='/achform' component={achForm} />
	  <Route path='/documentupload' component={Jdocup} />
	  {/*
	  <Route path='/demo_signin' component={DemoSignin} />
	  <Route path='/dashboard-d' component={DealerDashboard} />
	  <Route path='/dashboard-c' component={CustomerDashboard} />
	  */}
      </Switch>
      </Router>
  );
}

export default App;
