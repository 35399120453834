import CForm from '../components/cform';


function Contact() {
  return (
      <div className="flex flex-col items-center content-center m-4 justify-items-center">
	  <CForm />
	  <div className="mt-4 text-center text-gray-400">
	      <a className="hover:text-gray-500 transition-all" href="https://www.google.com/maps/place/200+Union+St,+Clinton,+MA+01510"> 200 Union St, Ste MZ4, Clinton, MA 01510</a> | <a className="hover:text-gray-500 transition-all" href="mailto:support@eastonfin.com">support@eastonfin.com</a> | <a className="hover:text-gray-500 transition-all" href="tel:+1 743 222 3622">+1 743 222 3622</a>
	  </div>
      </div>
  );
}

export default Contact;
